.chat-container {
    background-color: #2e2e2e;
    height: 410px;
    width: 700%;
    max-width: 1270px;
    margin: 0 auto;
}

.chat-header {
    background-color: black;
    padding: 10px;
    text-align: center;
    border-bottom: 2px solid #ff914d;
    color: white;
    font-size: 18px;
    height: 50px;
    border-radius: 8px;
    background-color: black;
    box-shadow: 0 0 10px rgba(18, 0, 0, 0.5);
}


.chat-main-div {
    height: 300px;
    margin-top: 10px;
    margin-bottom: 5px;
    overflow-y: scroll;
}

.input-div {
    display: flex;
    padding: 10px;
    background-color: #3a3a3a;
    border-top: 2px solid #ff914d;
    width:100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 60px;
    border-radius: 8px;
    background-color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.input-div input {
    flex: 1;
    padding: 10px;
    background-color: #4a4a4a;
    color: #ffffff;
    border: none;
    resize: none;
    outline: none;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 10px;
}

.input-div button {
    /* padding: 10px 15px; */
    background-color: #ff914d;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.2s;
}

.main-user-messages {
    flex-direction: row-reverse;
    display: flex;
    margin-top:10px;
    margin-bottom: 10px;
}

.main-bot-messages {
    flex-direction: row;
    display: flex;

}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
}

.user-message {
    background-color: #ff914d;
    /* align-self: flex-end; */
    color: #ffffff;
    flex-direction: row-reverse;
    display: flex;
}

.bot-message {
    background-color: #3a3a3a;
    align-self: flex-start;
    color: #ffffff;
    flex-direction: row;
}

.message {
    padding: 8px;
    border-radius: 6px;
    max-width: 80%;
    word-wrap: break-word;
    font-size: 14px;
}

/* Mobile-first styles */
@media (max-width: 390px) {
    .chat-container {
        width:390px ;
        height: 400px;
    }

    .chat-main-div{
        width:390px ;
    }

    .chat-header {
        width:390px ;
    }

    .input-div {
        width:390px ;
    }
}

