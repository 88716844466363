.ai-qa-list .qa-row h4 {
  width: 75%;
}

.ai-qa-list .meta-tag {
  display: flex;
  gap: 12px;
}

.ai-qa-list .meta-tag .type {
  width: 150px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.ai-qa-list .meta-tag .type img {
  width: 20px;
}

.save-as {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 5px;

}

.save-as select,
.save-as button {
  height: 38px
}