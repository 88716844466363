.header {
  min-height: 64px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 16px;
}
.header h5 {
  margin: 0;
}
.header i {
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.overlayOpen {
  opacity: 1;
  z-index: 1051;
  visibility: visible;
}

.overlayHidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.drawer {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;

  background-color: #ffffff;
  width: 400px;
  flex-shrink: 0;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.hidden {
  visibility: hidden;
  width: 240px;
  transform: translateX(240px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.animate {
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.drawerOpen {
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}
.wizard-content {
  padding: 16px;
}
.wizard-content .type {
  cursor: pointer;
  width: 100%;
  margin-right: 0;
  border: none;
  min-height: 4.8rem;
  height: auto;
  padding: 0.7rem 1.46rem;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #d6dce4;
  box-shadow: 0 0.8rem 1.33rem #0000000d;
  border-radius: 1rem;
  list-style-type: none;
  margin-top: 0.93rem;
}
.wizard-content .type:hover {
  transition: all 0.3s;
  border: 1px solid #1a78fe;
  background-color: transparent;
}
.wizard-content .ai-content-header {
  height: 40px;
  width: 100%;
  color: white;
  background-color: #2f396e;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.wizard-content .ai-content-header i {
  cursor: pointer;
}
.wizard-content .ai-content {
  border: 1px solid #d6dce4;
  border-radius: 3px;
}
