.referral {
  background: rgb(205, 184, 150);
  padding: 40px 24px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.referral p {
  color: rgb(117, 35, 84);
  text-align: center;
  margin: 0;
}
.uppercase {
  text-transform: uppercase;
}
.text-head {
  font-size: 64px;
  line-height: 64px;
  font-weight: bold;
}
.text-head2 {
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}
.text-head3 {
  font-size: 26px;
  line-height: 30px;
}
.text-head4 {
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
}
.mt-20 {
  margin-top: 30px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.referral .main-login-sec .login-form h2,
.referral .main-login-sec .login-form h3,
.referral .main-login-sec .login-form button,
.referral .main-login-sec .reg-form h2,
.referral .main-login-sec .reg-form h3,
.referral .main-login-sec .reg-form button {
  color: rgb(117, 35, 84);
}
.referral .main-login-sec .login-form form .sbm-sec button:hover,
.referral .main-login-sec .reg-form form .sbm-sec button:hover {
  transition: 0.3s all;
  background-color: rgb(117, 35, 84);
}
.referral .main-login-sec {
  padding: 40px 20px;
  background: rgb(160, 108, 116);
  border-radius: 20px;
}
.referral .welcome {
  padding: 40px;
}
