.affiliate img {
  width: 30%;
}

.affiliate section {
  padding-top: 50px;
}
.affiliate-body {
  border-radius: 20px;
  background: #ba90a8;
  padding: 12px 24px;
  margin-top: 20px;
}
.affiliate-body .left-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.affiliate-body .left-panel p {
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  background: rgb(204, 184, 149);
  padding: 20px;
  text-align: center;
  border-radius: 50px;
  margin: 20px;
}
.affiliate-body .right-panel {
  background: #f9f9ff;
  padding: 24px;
}

.affiliate .affiliate-body p {
  font-size: 1.46rem;
}
.affiliate .affiliate-body span {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
.affiliate .affiliate-body ul {
  margin-bottom: 20px;
}
.affiliate .affiliate-body li {
  font-size: 18px;
  line-height: 30px;
}
.affiliate .affiliate-body li i {
  color: var(--blue);
  margin-right: 12px;
}
.affiliate .affiliate-body input {
  height: 40px;
  line-height: 1.21428571em;
  font-family: var(--main-font);
  padding: 0.67857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #546880;
  border-radius: 0;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  margin-right: 12px;
  flex: 1 0 auto;
}
.affiliate .affiliate-body button {
  background: var(--background-color-gold);
  border: 1px solid var(--background-color-grey);
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 16px;
}
.affiliate .affiliate-body .social-buttons {
  margin-top: 20px;
}
.affiliate .affiliate-body .social-buttons button {
  margin-right: 12px;
}
