.roadmap {
  padding: 20px;
  max-width: 900px;
}
.roadmap .title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.roadmap .title-section img {
  width: 150px;
}
.roadmap .title-section h3 {
  color: rgb(35, 33, 118);
  font-weight: bold;
}
.roadmap .roadmap-section {
  margin-top: 40px;
}
.roadmap .roadmap-section .lessons {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid rgb(35, 33, 118);
}
.roadmap .roadmap-section .lessons .category {
  width: calc(25% - 10px);
  height: 40px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #d2d2d2;
  color: #232176;
  border: 2px solid #232176;
  text-align: center;
  line-height: 36px;
  overflow: hidden;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
}
.roadmap .lessons .group {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 10px 0px;
  width: calc(75% - 10px);
  justify-content: flex-start;
}
.roadmap .lessons .group.no-border {
  border: none;
}
.roadmap .lessons .group.no-border .lesson {
  border-radius: 0;
}
.roadmap .lessons .lesson {
  background-color: rgb(35, 33, 118);
  height: 40px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  padding: 0px 8px;
  width: calc(33.33% - 10px);
  min-width: 100px;
  box-sizing: border-box;
}
.roadmap .lessons .lesson.stuck {
  background-color: #db3d28;
}
.roadmap .lessons .lesson.focus {
  background-color: #ff914d;
}
.roadmap .lessons .lesson.complete {
  background-color: #526a48;
}
.lessons.labels {
  margin-top: 50px;
  justify-content: end;
  border: none !important;
}
@media (max-width: 768px) {
  .roadmap .title-section h3 {
    font-size: 12px;
  }
  .roadmap .title-section img {
    width: 100px;
  }
}
