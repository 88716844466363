/* .card {
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 5px 5px 11px #a6a6a6, -5px -5px 11px #ffffff;
}
.img {
	width: 100%;
}

:root {
	--color-p: #3a5cd1;
	--color-white: #fff;
	--color-light-gray: #eff2f5;
	--color-mid-gray: #96a0b5;
	--color-dark-gray: #6d7d93;
	--color-dark: #182550;
	--color-red: #d13a3a;
}

h1 {
	font-size: 2.441rem;
	line-height: 1.4rem;
}

label {
	font-size: 16px;
}
h2 {
	font-size: 25px;
}

h3 {
	font-size: 1.563rem;
}

h4 {
	font-size: 1.25rem;
}

a {
	text-decoration: none;
	color: var(--color-p);
	transition: all 0.3s ease-out;
}
a:hover {
	color: var(--color-dark);
}

.text-center {
	text-align: center;
}

.text-underline {
	text-decoration: underline;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: 24px;
}

img,
svg {
	display: block;
	max-width: 100%;
	height: auto;
}

.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

label {
	display: block;
	color: var(--color-dark-gray);
}

input {
	background-color: var(--color-light-gray);
	padding: 0.5rem;
	border: 1px solid transparent;
	width: 100%;
	border-radius: 6px;
	font-size: 14px !important;
	font-family: Dongle, sans-serif;
}
input:focus {
	color: var(--color-dark);
	background-color: var(--color-white);
	border-color: var(--color-p);
	outline: 0;
	border: 1px solid var(--color-p);
}
input.error {
	border: 1.5px solid #d13a3a;
}
select.form-control:not([size]):not([multiple]) {
	height: 40px !important;
	font-size: 14px !important;
}

.form-radio,
.form-checkbox {
	appearance: none;
	display: inline-block;
	position: relative;
	background-color: var(--color-white);
	border: 2px solid #bdbcc0;
	height: 24px;
	width: 24px;
	border-radius: 50px;
	cursor: pointer;
	margin: 0 10px 0 0;
	outline: none;
	padding: 0;
}

.form-radio:checked::before,
.form-checkbox:checked::before {
	position: absolute;
	left: 5px;
	top: 1px;
	content: "⅃";
	font-size: 14px;
	transform: rotate(40deg);
}

.form-radio:hover,
.form-checkbox:hover {
	background-color: var(--color-mid-gray);
	outline: none;
}

.form-radio:checked,
.form-checkbox:checked {
	background-color: var(--color-p);
	border-color: var(--color-dark);
	color: var(--color-white);
	z-index: 2;
}

.form-radio {
	border: 2px solid #bdbcc0;
	top: 6px;
}

.form-checkbox {
	border-radius: 4px;
}

.error-text {
	color: #d13a3a;
}

.remember-forgot {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.remember {
	display: flex;
	align-items: center;
}
.remember label {
	display: inline-block;
	line-height: 1;
}

.forgot {
	line-height: 1;
	text-align: right;
}

button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #db3e28;
	color: var(--color-white);
	border: 0;
	padding: 5px 20px;
	width: 100%;
	border-radius: 5px !important;
	font-weight: 400;
	font-size: 20px !important;
	cursor: pointer;
	transition: all 0.3s ease-out;
}
button:hover {
	background-color: var(--color-dark);
}

p {
	font-size: 15px;
} */


.form-group {
	margin-bottom: 1rem;
	text-align: left;
}

.label {
	display: block;
	color: #6d7d93;
}

.input {
	background-color: #dcdcdc;
	padding: 0.5rem;
	border: 1px solid transparent;
	width: 100%;
	border-radius: 6px;
	font-size: 14px !important;
	font-family: Dongle, sans-serif;
}
.card {
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 5px 5px 11px #a6a6a6, -5px -5px 11px #ffffff !important;
}
.select.form-control:not([size]):not([multiple]) {
	height: 40px !important;
	font-size: 14px !important;
}

.mybutton {
	
	background-color: #db3e28;
	color:white;
	border: 0;
	padding: 5px 20px;
	width: 100%;
	border-radius: 5px !important;
	font-weight: 400;
	font-size: 20px !important;
	cursor: pointer;
	transition: all 0.3s ease-out;
}