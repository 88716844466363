.weekly-tracker {
  padding: 0;
  max-width: 1250px;
  padding-top: 20px;
}
.row-names {
  display: flex;
  align-items: center;
  gap: 20px;
}
.row-names > div {
  width: 20%;
  text-align: center;
}
.row-names label {
  /* max-width: 140px; */
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  background-color: #d2d2d2;
  color: #232176;
  border: 2px solid #232176;
  text-align: center;
  border-radius: 20px;
  line-height: 36px;
  margin: 0;
  text-transform: uppercase;
}
.goal-row {
  display: flex;
}
.goal-row {
  /* border-bottom: 1px solid var(--background-color-gold); */
}
.row-body {
  background-color: rgba(35, 33, 118, 0.5);
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
}
.row-body .goal-row > div {
  width: 20%;
  padding: 12px 8px;
  background-color: white;
  border: 2px solid #246175;
  border-radius: 8px;
  margin: 4px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  color: #232176;
}
.row-body .goal-row > div > p {
  margin: 0;
}
.row-body .goal-row .focus {
  background-color: #ff914d;
  color: white;
}
.centered-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .row-body {
    padding: 0px;
  }
  .row-body .goal-row > div {
    padding: 2px;
  }
  .centered-text > div {
    width: 100%;
  }
  .centered-text > div > p {
    word-break: break-all;
  }
}
