.ai-content .types .type {
  border: 2px solid rgb(117, 35, 84);
  border-radius: 8px;
  padding: 4px;
}
.ai-content .types .type p {
  color: rgb(117, 35, 84);
  text-align: center;
  font-weight: bold;
}
.ai-content .types .type:hover {
  border-color: rgb(205, 184, 150);
}
.ai-content .types .type img.default {
  display: block;
}
.ai-content .types .type img.hover {
  display: none;
}
.ai-content .types .type:hover img.default {
  display: none;
}
.ai-content .types .type:hover img.hover {
  display: block;
}
.ai-content .types .type:hover p {
  transition: all 0.4s;
  color: rgb(205, 184, 150);
}
.ai-content .types .type.active {
  border-color: rgb(205, 184, 150);
}
.ai-content .types .type.active img.default {
  display: none;
}
.ai-content .types .type.active img.hover {
  display: block;
}
.ai-content .types .type.active p {
  color: rgb(205, 184, 150);
}
.ai-content .types .d-flex {
  justify-content: center;
  gap: 40px;
  grid-gap: 40px;
  display: flex;
  margin-top: 40px;
}
.ai-content .types img {
  width: 150px;
  cursor: pointer;
}
.ai-content .answer {
  margin-top: 30px;
}
.ai-content .answer p {
  font-size: 18px;
}
.ai-content .brief {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
}
.ai-content .brief img {
  width: 64px;
}
.ai-content .brief p {
  margin: 0;
  color: #232176;
  font-size: 20px;
  line-height: 23px;
}
.feedback {
  display: flex;
  gap: 8px;
  justify-content: end;
  margin-bottom: 8px;
}
.feedback i {
  cursor: pointer;
  font-size: 18px;
}
.feedback .fa-thumbs-o-up:hover {
  transition: 0.3s all;
  color: #2bb12b;
}
.feedback .fa-thumbs-o-down:hover {
  color: #d1112a;
}
.modal-dialog-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: end;
  min-height: 50%;
}
.feedback-modal .modal-dialog {
  max-width: 700px;
}
.feedback-modal .modal-content {
  border-radius: 4px;
}
.feedback-modal textarea {
  width: 100%;
  padding: 8px 12px;
  min-height: 135px;
}
.feedback-modal .modal-header {
  justify-content: center;
  border-bottom: 1px solid #e2e2e2 !important;
}
.feedback-modal .modal-header .fa-thumbs-o-up {
  color: #2bb12b;
}
.feedback-modal .modal-header .fa-thumbs-o-down {
  color: #d1112a;
}
.feedback-modal .modal-header p {
  text-align: center;
  font-size: 20px;
}
.feedback-modal .modal-body {
  padding: 2rem;
}
.feedback-modal .feedback-btn {
  display: flex;
  justify-content: end;
  margin-top: 12px;
}
.feedback-modal button {
  background-color: var(--background-color-grey);
}
@media (max-width: 768px) {
  .feedback-modal .modal-dialog {
    max-width: 100%;
  }
  .feedback-modal .modal-body {
    padding: 16px;
  }
}
